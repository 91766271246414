import React, { useState, useEffect } from 'react';
import TriggerButton from '../TiggerButton/TriggerButton';
import './Fetching.css'

interface InfoData {
  raceTime: string;
  raceNumber: string;
  action: string;
  minutes: string;
  ranking: string;
  command: string;
  updatedTime: string;
}

const Fetching: React.FC = () => {
  const [info, setInfo] = useState<InfoData | null>(null);
  const currentDate = new Date().toLocaleDateString('en-CA', { timeZone: 'Asia/Hong_Kong' });

  useEffect(() => {
    const fetchData = () => {
      fetch('https://api-rke-hkjc.agileexlab.net/llm/v4/api/state', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_date: currentDate,
          race_date: currentDate,
          race_num: 0,
          user_info: {
            id: "9999",
            balance: "0.0",
            is_empty: true,
          },
        }),
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          setInfo({
            raceTime: data.payload.expected_race_date,
            raceNumber: data.payload.expected_race_num.toString(),
            action: data.payload.action,
            minutes: data.payload.expected_snapshot.toString(),
            ranking: '', // Update this with the actual ranking data  
            command: '', // Update this with the actual command data  
            updatedTime: new Date().toLocaleTimeString(),
          });
        })
        .catch(error => console.error('Error:', error));
    };

    // Fetch data immediately  
    fetchData();

    // Fetch data every 10 seconds  
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup on unmount  
    return () => clearInterval(intervalId);
  }, []);

  return (
    // {info && <TriggerButton raceDate={info.raceTime} raceNumber={parseInt(info.raceNumber)} />}
    <>
      {info && (
        <div className='info-box' >
          {/* <h1>Health Check</h1> */}
          <p>Race Time: {info.raceTime}</p>
          <p>Race Number: {info.raceNumber}</p>
          <p>Updated Time: {info.updatedTime}</p>
          <p>Current Minute: {info.minutes}</p>
        </div>
      )}
      {info && <TriggerButton info={info} raceDate={info.raceTime} raceNumber={parseInt(info.raceNumber)} minutes={parseInt(info.minutes)} />}
    </>
  );
};

export default Fetching;
