import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface PersonRow {
  name: string;
  top3: string[];
  next5: string[];
  other: string[];
}

interface BetCommandInputProps {
  betCommandLabel: string;
  handleCustomBetClick: (selectedRows: any, rows: any) => void;
  handleCustomBetClickCommandLine: (command: any) => void
}

interface TableRowData {
  label: string;
  numbers: string[];
  amount: string;
}
const LOCAL_STORAGE_KEY = 'betAmounts';

// Function to get the initial state based on the username from local storage  
const getInitialStateCurrentUser = (): PersonRow[] => {
  const username = localStorage.getItem('username');
  const defaultRow = { top3: ["", "", ""], next5: ["", "", "", "", ""], other: ["", "", ""] };

  switch (username?.toLowerCase()) {
    case '#991':
      return [{ name: "Steve", ...defaultRow }];
    case 'bobby':
      return [{ name: "Bobby", ...defaultRow }];
    case '#990':
      return [{ name: "Syrus", ...defaultRow }];
    default:
      // Return an empty array or handle the case where the username is not found  
      return [];
  }
};
// Function to get the initial state based on the username from local storage  
const getInitialStateNonUser = (): PersonRow[] => {
  const username = localStorage.getItem('username');
  const defaultRow = { top3: ["", "", ""], next5: ["", "", "", "", ""], other: ["", "", ""] };

  switch (username?.toLowerCase()) {
    case '#991':
      return [{ name: "Bobby", ...defaultRow }, { name: "Syrus", ...defaultRow }];
    case 'bobby':
      return [{ name: "Syrus", ...defaultRow }, { name: "Steve", ...defaultRow }];
    case '#990':
      return [{ name: "Bobby", ...defaultRow }, { name: "Steve", ...defaultRow }];
    default:
      return [{ name: "Bobby", ...defaultRow }, { name: "Steve", ...defaultRow }, { name: "Syrus", ...defaultRow }];
  }
};

const BetCommandInput: React.FC<BetCommandInputProps> = ({ betCommandLabel, handleCustomBetClick, handleCustomBetClickCommandLine }) => {
  // const [rows, setRows] = useState<TableRowData[]>(initialRows); 
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>({});
  const [currentBettingCommands, setCurrentBettingCommands] = useState<string[]>([]);

  const handleRowSelectionChange = (rowIndex: number, isSelected: boolean) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [rowIndex]: isSelected,
    }));
  };

  const [personRows, setPersonRows] = useState<PersonRow[]>(getInitialStateCurrentUser);
  const [personRowsNonUser, setPersonRowsNonUser] = useState<PersonRow[]>(getInitialStateNonUser);

  const handlePersonNumberChange = (
    rowIndex: number,
    section: keyof PersonRow,
    numberIndex: number,
    value: string
  ) => {
    const updatedRows = [...personRows];
    const sectionData = updatedRows[rowIndex][section];

    // Type guard to check if sectionData is an array  
    if (Array.isArray(sectionData)) {
      sectionData[numberIndex] = value;
      setPersonRows(updatedRows);
    } else {
      // Handle the error or provide feedback to the user  
      console.error('Attempted to modify a non-array section');
    }
  };

  const numberRegExp = /^[0-9]*$/;
  const fetchDefaultPreferredRanking = async () => {
    try {
      const response = await fetch('https://api-rke-hkjc.agileexlab.net/ihr-luck/v4/api/betbot/get-user-preferred-betting-command', {
      // const response = await fetch('http://localhost:8000/api/betbot/get-user-preferred-betting-command', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          username: "test",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPersonRows(personRows.map((row) => {
        // console.log(row.name);
        // console.log(data.payload);
        switch (row.name) {
          case 'Steve':
            if (data.payload.steve_ranking.top3.length > 0) {
              return { ...row, ...data.payload.steve_ranking };
            } else return row;
          case 'Bobby':
            if (data.payload.bobby_ranking.top3.length > 0) {
              return { ...row, ...data.payload.bobby_ranking };
            } else return row;
          // return { ...row, ...data.payload.bobby_ranking };
          case 'Syrus':
            if (data.payload.syrus_ranking.top3.length > 0) {
              return { ...row, ...data.payload.syrus_ranking };
            } else return row;
          // return { ...row, ...data.payload.syrus_ranking };
          default:
            return row;
        }
      }));
      setPersonRowsNonUser(personRowsNonUser.map((row) => {
        // console.log(row.name);
        // console.log(data.payload);
        switch (row.name) {
          case 'Steve':
            if (data.payload.steve_ranking.top3.length > 0) {
              data.payload.steve_ranking.top3 = data.payload.steve_ranking.top3.map((item: string) => item === "" ? "-" : item);
              data.payload.steve_ranking.next5 = data.payload.steve_ranking.next5.map((item: string) => item === "" ? "-" : item);
              data.payload.steve_ranking.other = data.payload.steve_ranking.other.map((item: string) => item === "" ? "-" : item);
              // const steve_ranking = data.payload.steve_ranking.top3.map((item :any) => item === "" ? "-" : item);
              return { ...row, ...data.payload.steve_ranking };
            } else return row;
          case 'Bobby':
            if (data.payload.bobby_ranking.top3.length > 0) {
              data.payload.bobby_ranking.top3 = data.payload.bobby_ranking.top3.map((item: string) => item === "" ? "-" : item);
              data.payload.bobby_ranking.next5 = data.payload.bobby_ranking.next5.map((item: string) => item === "" ? "-" : item);
              data.payload.bobby_ranking.other = data.payload.bobby_ranking.other.map((item: string) => item === "" ? "-" : item);
              // const bobby_ranking = data.payload.bobby_ranking.top3.map((item :any) => item === "" ? "-" : item);
              return { ...row, ...data.payload.bobby_ranking };
            } else return row;
          case 'Syrus':
            if (data.payload.syrus_ranking.top3.length > 0) {
              data.payload.syrus_ranking.top3 = data.payload.syrus_ranking.top3.map((item: string) => item === "" ? "-" : item);
              data.payload.syrus_ranking.next5 = data.payload.syrus_ranking.next5.map((item: string) => item === "" ? "-" : item);
              data.payload.syrus_ranking.other = data.payload.syrus_ranking.other.map((item: string) => item === "" ? "-" : item);
              return { ...row, ...data.payload.syrus_ranking };
            } else return row;
          // return { ...row, ...data.payload.syrus_ranking };
          default:
            return row;
        }
      }));
    } catch (error) {
      console.error('Failed to fetch default values:', error);
    }
  };
  const handlePreferredRankingSubmit = async (rowIndex: number) => {
    const row = personRows[rowIndex];
    try {
      const username = localStorage.getItem('username');
      // console.log(row)
      const response = await fetch('https://api-rke-hkjc.agileexlab.net/ihr-luck/v4/api/betbot/update-user-preferred-betting-command', {
        // const response = await fetch('http://localhost:8000/api/betbot/update-user-preferred-betting-command', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          username: username,
          top3_ranking: row.top3,
          next5_ranking: row.next5,
          other_ranking: row.other,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Submit successful:', result);
      // Handle the response as needed  
    } catch (error) {
      console.error('Submit failed:', error);
      // Handle the error as needed  
    }
  };
  // Fetch current betting commands from the backend  
  useEffect(() => {
    const username = localStorage.getItem('username');
    const fetchCurrentBetting = async () => {
      try {
        const response = await fetch('https://api-rke-hkjc.agileexlab.net/ihr-luck/v4/api/betbot/get-current-betting', {
          // const response = await fetch('http://localhost:8000/api/betbot/get-current-betting', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            username: username,
          }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCurrentBettingCommands(data.payload);
      } catch (error) {
        console.error('There was a problem fetching current betting commands:', error);
      }
    };

    fetchCurrentBetting();
    // Fetch data every 10 seconds  
    const intervalId = setInterval(fetchCurrentBetting, 10000);

    // Cleanup on unmount  
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run only once on component mount  

  // Load initial data from local storage  
  useEffect(() => {
    const storedRows = localStorage.getItem(LOCAL_STORAGE_KEY);
    // console.log("Got old rows", storedRows, storedRows?.length)
    if (storedRows && storedRows.length > 2) {
      setRows(JSON.parse(storedRows));
    } else {
      // If there is no data in local storage, initialize with default values  
      // console.log("No old rows found, initializing with default values")
      setRows([
        { label: "WIN", numbers: Array(8).fill(''), amount: '100' },
        { label: "PLA", numbers: Array(8).fill(''), amount: '100' },

        { label: "TB", numbers: Array(8).fill(''), amount: '10' },
        { label: "TBM", numbers: Array(8).fill(''), amount: '6' },
        { label: "TM", numbers: Array(8).fill(''), amount: '6' },

        { label: "QB", numbers: Array(8).fill(''), amount: '2' },
        { label: "QBM", numbers: Array(8).fill(''), amount: '1' },
        { label: "QM", numbers: Array(8).fill(''), amount: '1' },

        { label: "QIN", numbers: Array(8).fill(''), amount: '40' },
        { label: "QQP", numbers: Array(8).fill(''), amount: '40' },
        { label: "FB", numbers: Array(8).fill(''), amount: '40' }
      ]);
    }
  }, []);
  useEffect(() => {
    const betCommandPattern = /(\w+):(\d+)>([\d,]+)\/\$(\d+)/;
    if (!betCommandLabel || betCommandLabel === '') {
      return;
    }
    // console.log(betCommandLabel)
    const match = betCommandLabel.match(betCommandPattern);
    if (match) {
      const [, , firstNumber, subsequentNumbers] = match;
      const numbersArray = subsequentNumbers.split(',');
      const totalBoxes = 8; // Total number of boxes you want  

      setRows((currentRows) => currentRows.map((row, index) => {
        if (row.label === "WIN" || row.label === "PLA") {
          return { ...row, numbers: [firstNumber, ...Array(7).fill('')] };
        }
        const filledNumbers = [firstNumber, ...numbersArray.slice(0, totalBoxes - 1)];
        const paddedNumbers = filledNumbers.concat(Array(totalBoxes - filledNumbers.length).fill(''));
        return {
          ...row,
          numbers: paddedNumbers,
        };
      }));
    }
  }, [betCommandLabel]);

  // Save data to local storage whenever it changes  
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(rows));
  }, [rows]);

  // Call this function when the component mounts  
  useEffect(() => {
    fetchDefaultPreferredRanking();
  }, []);
  const handleNumberChange = (rowIndex: number, numberIndex: number, value: string) => {
    const newRows = [...rows];
    newRows[rowIndex].numbers[numberIndex] = value.slice(0, 2); // Ensure only 2 digits  
    setRows(newRows);
  };

  const handleAmountChange = (rowIndex: number, value: string) => {
    const newRows = [...rows];
    newRows[rowIndex].amount = value.slice(0, 3); // Ensure only 3 digits  
    setRows(newRows);
  };

  const clearLocalStorage = () => {
    // Clear the data from local storage  
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    // Reset the rows state to the initial state  
    setRows([
      { label: "WIN", numbers: Array(8).fill(''), amount: '100' },
      { label: "PLA", numbers: Array(8).fill(''), amount: '100' },

      { label: "TB", numbers: Array(8).fill(''), amount: '10' },
      { label: "TBM", numbers: Array(8).fill(''), amount: '6' },
      { label: "TM", numbers: Array(8).fill(''), amount: '6' },

      { label: "QB", numbers: Array(8).fill(''), amount: '2' },
      { label: "QBM", numbers: Array(8).fill(''), amount: '1' },
      { label: "QM", numbers: Array(8).fill(''), amount: '1' },

      { label: "QIN", numbers: Array(8).fill(''), amount: '40' },
      { label: "QQP", numbers: Array(8).fill(''), amount: '40' },
      { label: "FB", numbers: Array(8).fill(''), amount: '40' }
      // ... any other default rows you want to initialize  
    ]);
    // Also clear selected rows if needed  
    setSelectedRows({});
  };
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    <h3 style={{ margin: 0 }}>Custom Command</h3>
    <input
      type="text"
      id="commandInput"
      placeholder="Enter command here"
      style={{
        padding: '8px',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        flex: 1,
      }}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        const inputValue = (document.getElementById('commandInput') as HTMLInputElement).value;
        handleCustomBetClickCommandLine(inputValue);
        // console.log(inputValue);
      }}
    >
      Submit
    </Button>
  </div>
  <Button variant="outlined" color="secondary" onClick={clearLocalStorage}>
    Clear
  </Button>
</div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="none">Select</TableCell>
              <TableCell padding="none">Pool</TableCell>
              <TableCell>Numbers</TableCell>
              <TableCell padding="none">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell padding="none">
                  <Checkbox
                    size="small"
                    checked={selectedRows[rowIndex] || false}
                    onChange={(e) => handleRowSelectionChange(rowIndex, e.target.checked)}
                  />
                </TableCell>
                <TableCell padding="none">{row.label}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      size="small"
                      type="text"
                      value={row.numbers[0]}
                      onChange={(e) => {
                        const re = /^[0-9]+$/; //regular expression allowing only numbers and backspace  
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleNumberChange(rowIndex, 0, e.target.value)
                        }
                      }}
                      // onChange={(e) => handleNumberChange(rowIndex, 0, e.target.value)}
                      inputProps={{
                        maxLength: 3,
                      }}
                      sx={{ width: '4em', marginRight: '12px' }} // Adjust marginRight as needed  
                    />
                    <span>&gt;</span>
                    {row.numbers.slice(1).map((number, numberIndex) => (
                      <TextField
                        key={numberIndex + 1} // +1 because we already have the first input rendered separately  
                        size="small"
                        type="text"
                        value={number}
                        onChange={(e) => {
                          const re = /^[0-9]+$/; //regular expression allowing only numbers and backspace  
                          if (e.target.value === '' || re.test(e.target.value)) {
                            handleNumberChange(rowIndex, numberIndex + 1, e.target.value)
                          }
                        }}
                        // onChange={(e) => handleNumberChange(rowIndex, numberIndex + 1, e.target.value)}
                        inputProps={{
                          maxLength: 3,
                        }}
                        sx={{ width: '4em', marginLeft: numberIndex === 0 ? '12px' : '6px' }} // Adjust marginLeft for the first element  
                      />
                    ))}
                  </div>
                </TableCell>
                <TableCell padding="none">
                  <TextField
                    // size="small"
                    type="text"
                    value={row.amount}
                    onChange={(e) => handleAmountChange(rowIndex, e.target.value)}
                    inputProps={{
                      maxLength: 3, // Increase maxLength to accommodate 6 digits and a possible decimal point
                      style: { textAlign: 'right' },
                    }}
                    sx={{ width: '6em' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={() => handleCustomBetClick(selectedRows, rows)}>
        Place Bet
      </Button>
      <h3>Betted Command</h3>
      <Paper style={{ maxHeight: 200, overflow: 'auto', marginTop: '1em', marginBottom: '1em' }}>
        <List dense>
          {currentBettingCommands.map((command, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText primary={command} />
              </ListItem>
              {/* Add a divider between list items except the last one */}
              {index < currentBettingCommands.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <h3>Base Command</h3>
      <Paper style={{ maxHeight: 200, overflow: 'auto', marginTop: '1em', marginBottom: '1em' }}>
        <List dense>
          <ListItem>
            <ListItemText primary={betCommandLabel} />
          </ListItem>

        </List>
      </Paper>
    </div>
  );
};

export default BetCommandInput;  