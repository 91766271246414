import axios, { AxiosResponse } from "axios";
import "./component/style/Encrypt.css";
import JSEncrypt from "jsencrypt";
import { LoadingContext } from "../Loading/Context";
import { useContext, useEffect, useState } from "react";
import { PublicKeyInfo } from "../Types/Types";
import { removeStringQuote } from "../Helper";

export default function GetPublicKey(): PublicKeyInfo {
  const { loadingFinish } = useContext(LoadingContext);
  const url = 'https://api-rke-hkjc.agileexlab.net/ihr-luck/v4/api/pubkey';
  const [fetchedData, setFetchedData] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      await axios
        .post(url)
        .then((res) => {
          setFetchedData(res.data.payload);
          if (res.data?.payload?.rsa_public !== "") {
            // console.log(removeStringQuote(JSON.stringify(res.data?.payload?.rsa_public)));
            localStorage.setItem("publicKey", removeStringQuote(JSON.stringify(res.data?.payload?.rsa_public)));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loadingFinish()
        });
    };
    getData();
  }, [loadingFinish]);
  const result = fetchedData ? generatePostBody(fetchedData) : initPostBody();
  // console.log("My result:", result)
  return result;
}

export const useEncrypt = () => {
  const encryptWithPublicKey = (password: string, publicKey: string) => {
    const result = print_encrypted(password, publicKey, "Original");
    console.log(result);
    return result;
  }

  function print_encrypted(pass: string, pub: string, msg: string): string {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(pub); // need to replace \n to ""
    const encrypted = jsEncrypt.encrypt(pass);
    return encrypted === false ? "false" : encrypted;
  }
  return { encryptWithPublicKey }
}


function generatePostBody(data: any): PublicKeyInfo {
  return {
    publicKey: data.rsa_public || ""
  }
}

function initPostBody(): PublicKeyInfo {
  return {
    publicKey:  ""
  }
}