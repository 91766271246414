// template-folder-name -> ProfilePageContent.tsx
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../../component/Loading/Context';
import { useLogin } from '../../../../component/Login';
import { useEncrypt, GetPublicKey } from '../../../../component/Encrypt';
import '../style/LoginPage.css';
import { removeStringQuote } from '../../../../component/Helper';

export function LoginPageContent(userInfo: any) {
  const { encryptWithPublicKey } = useEncrypt();
  const { loadingBool } = useContext(LoadingContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { verifyLogin } = useLogin();
  const url = 'https://api-rke-hkjc.agileexlab.net/ihr-luck/v4/api/pubkey';
  useEffect(() => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        localStorage.setItem("publicKey", removeStringQuote(JSON.stringify(data.data?.payload?.rsa_public)));
      })
      .catch(error => console.error('Error:', error));
  }, []);
  const result = GetPublicKey();
  return (
    <>
    { !loadingBool && (
      <div className='login-page-container'>
        <form className="login-form">
          <span className="login-title">IHR Luck Login</span>
          <input type="text" id="username" name="username" placeholder="Username"
            onChange={(e) => { setUsername(e.target.value); localStorage.setItem("username", e.target.value.toLowerCase()) }} />
          <input type="password" id="password" name="password" placeholder="Password"
            onChange={(e) => setPassword(e.target.value)} />
          <button type="button" className="login-button"
            onClick={() => { const updatedPublicKey = localStorage.getItem("publicKey") || ""; verifyLogin(username.toLowerCase(), encryptWithPublicKey(password, updatedPublicKey).toString()); }} >Login</button>
        </form>
      </div>
      )
    }
    </>
  )
}