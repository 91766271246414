import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import './TriggerButton.css';

interface TriggerButtonProps {
  raceDate: string;
  raceNumber: number;
  info: any;
  minutes: number;
}
interface TriggerResponse {
  race_date: string;
  race_num: number;
  minute_snapshot: number;
  update_ts: string;
  message: string;
}
interface Ranking {
  gpt4: string;
  top3: number;
  next5: any;
}

const TriggerButton: React.FC<TriggerButtonProps> = ({ info, raceDate, raceNumber, minutes }) => {
  const [response, setResponse] = useState<TriggerResponse | null>(null);
  const [ranking, setRanking] = useState<Ranking | null>(null);
  const [hasTriggered, setHasTriggered] = useState<boolean>(false);
  // Add a new state for modal visibility  
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Open modal when minutes equals 0  
  useEffect(() => {
    if (minutes === 0) {
      setModalIsOpen(true);
    }
  }, [minutes]);
  // new state for delay  
  const [delay, setDelay] = useState<number | null>(null);

  const triggerPostRequest = () => {
    // api-rke-hkjc.agileexlab.net/llm/v4/api/trigger
    // api-hkjc.agileexlab.net/llm/v4/api/trigger
    fetch('https://api-rke-hkjc.agileexlab.net/llm/v4/api/trigger', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // race_date: raceDate,
        race_date: "2024-04-10",
        race_num: 7,
        minute_snapshot: 9999,
        // minute_snapshot: -1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setResponse(data.payload);
        setHasTriggered(true);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    const fetchData = () => {
      fetch('https://api-rke-hkjc.agileexlab.net/llm/v4/api/fetch_ranking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          race_date: raceDate,
          race_num: raceNumber,
          snapshot: 9999
          // snapshot: -1
        }),
      })
        .then(response => response.json())
        .then(data => {
          setRanking({
            gpt4: data.payload.gpt4.join(', '),
            top3: data.payload.top3.join(', '),
            next5: data.payload.next5.join(', ')
          });
        })
        .catch(error => console.error('Error:', error));
    };

    // Fetch data immediately  
    fetchData();
  }, []);

  // Update handleClick function  
  const handleClick = (decision: string) => {
    if (decision === "1") {
      // Do not close the modal here  
      if (hasTriggered) {
        alert("Post request has already been triggered for this minute. Please wait for the next minute.");
        return;
      }

      if (delay !== null) {
        // If a delay is already in progress, add 15 seconds to it  
        setDelay(delay => delay! + 15);
      } else {
        // Otherwise, start a new 15 seconds delay  
        setDelay(15);
      }
    } else {
      // Close the modal when "Trigger immediately" or "Cancel" is clicked  
      setModalIsOpen(false);

      if (hasTriggered) {
        alert("Post request has already been triggered for this minute. Please wait for the next minute.");
        return;
      }

      if (decision === "2") {
        triggerPostRequest();
      } else {
        console.log("Cancelled");
      }
    }
  };



  // // useEffect for delay countdown  
  // useEffect(() => {
  //   if (delay !== null && delay > 0) {
  //     const timeoutId = setTimeout(() => {
  //       setDelay(delay - 1);
  //     }, 1000);
  //     return () => clearTimeout(timeoutId);
  //   } else if (delay === 0) {
  //     triggerPostRequest();
  //     setDelay(null);
  //   }
  // }, [delay]);
  // useEffect(() => {  
  //   let intervalId: NodeJS.Timeout | null = null;  

  //   if (delay !== null) {  
  //     // Start a new interval if delay is not null  
  //     intervalId = setInterval(() => {  
  //       if (delay > 0) {  
  //         setDelay(delay => delay! - 1);  
  //       } else {  
  //         // Delay is 0, trigger the post request and clear interval  
  //         triggerPostRequest();  
  //         setDelay(null);  
  //         if (intervalId) clearInterval(intervalId);  
  //       }  
  //     }, 1000);  
  //   }  

  //   // Cleanup on unmount or when delay changes  
  //   return () => {  
  //     if (intervalId) clearInterval(intervalId);  
  //   };  
  // }, [delay]);  

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (delay !== null) {
      intervalId = setInterval(() => {
        if (delay > 0) {
          setDelay(delay => delay! - 1);
        } else {
          // Delay is 0, trigger the post request, clear interval and close modal  
          triggerPostRequest();
          setDelay(null);
          if (intervalId) clearInterval(intervalId);
          setModalIsOpen(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [delay]);

  // Reset hasTriggered when minutes change  
  useEffect(() => {
    setHasTriggered(false);
  }, [minutes]);

  return (
    <div>
      <div>
        {/* Rest of your component */}
        <ReactModal isOpen={modalIsOpen} className="modal-content" >
          <p className="remaining-time">Remaining time: {delay}</p>  
          <p>Race Time: {info.raceTime}</p>
          <p>Race Number: {info.raceNumber}</p>
          <p>Updated Time: {info.updatedTime}</p>
          <p>Updated Minute: {info.minutes}</p>
          <p>Do you really want to bet?</p>
          <button className="delay-button" onClick={() => handleClick("1")}>Add 15 seconds delay</button>
          <button className="immediate-button" onClick={() => handleClick("2")}>Trigger immediately</button>
          <button className="cancel-button" onClick={() => handleClick("3")}>Cancel</button>
        </ReactModal>
      </div>
      <button onClick={() => handleClick("2")} className="trigger-button">  
        Generate Ranking
      </button>
      {/* {ranking && (
        <div className="ranking-container">
          <p>GPT-4: [{ranking.gpt4}]</p>
          <p>Top-3: [{ranking.top3}]</p>
          <p>Next-5: [{ranking.next5}]</p>
        </div>
      )} */}
      {response && (
        <table className="trigger-table">
          <tbody>
            <tr>
              <th>Race Date</th>
              <td>{response.race_date}</td>
            </tr>
            <tr>
              <th>Race Number</th>
              <td>{response.race_num}</td>
            </tr>
            <tr>
              <th>Minute Snapshot</th>
              <td>{response.minute_snapshot}</td>
            </tr>
            <tr>
              <th>Update Timestamp</th>
              <td>{response.update_ts}</td>
            </tr>
            <tr>
              <th>Message</th>
              <td>{response.message}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TriggerButton;
